<template>

  <v-main>

    <v-container
        id="payment-information-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-bank-outline"
          class="px-5 py-3"
      >


        <v-card-text>

          <v-row>
            <v-col
                cols="12"
                sm="12"
            >
              <p>Submit funds to Tilyet Wells Fargo and send Agnes Mabwai, the Treasurer a screenshot of the transaction details and your full names via WhatsApp to: <span class="font-weight-bold"><a href="tel:1-612-598-1631">612-598-1631</a></span></p>
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                sm="2"
            >
              New and Legacy MN Residents:
            </v-col>
            <v-col
                cols="12"
                sm="4"
                class="font-weight-bold"
            >
              $500.00
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                sm="2"
            >
              College Student MN Residents:
            </v-col>
            <v-col
                cols="12"
                sm="4"
                class="font-weight-bold"
            >
              $250.00
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                sm="2"
                class="font-weight-bold"
            >
              Payment Options:
            </v-col>
            <v-col
                cols="12"
                sm="4"
            >
              <p class="font-weight-bold">Wells Fargo Bank</p>
              <p>Name: <span class="font-weight-bold">Tilyet Benevolence Fund</span></p>
              <p>Account #: <span class="font-weight-bold">5677028127</span></p>
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                sm="2"
                class="font-weight-bold"
            >
              Note:
            </v-col>
            <v-col
                cols="12"
                sm="4"
            >
              <p class="font-italic">Zelle is now an option for non-Wells Fargo members. Be sure to include the email
                address: tilyetfund@gmail.com to ensure your payment will be deposited to our account.</p>
            </v-col>
          </v-row>

        </v-card-text>

      </base-material-card>

    </v-container>
  </v-main>

</template>

<script>


export default {
  name: "CorePaymentInformationView",
  created() {
  },
  components: {
  },
  methods: {},
  data() {
    return {
    }
  }
};

</script>
